<template>
  <v-form
    class="form"
    @submit.prevent="logInGetToken"
    v-model="valid"
    ref="form"
  >
    <div class="form__logo">
      <LogoAuth />
    </div>
    <div class="form__title">
      {{ $t("authPages.loginPage.formTitle") }}
    </div>
    <div class="form__body">
      <v-text-field
        v-model="formData.username"
        outlined
        label="E-mail"
        type="email"
        persistent-placeholder
        dense
        :placeholder="$t('placeholders.email')"
        :rules="[validations.email, validations.required]"
      ></v-text-field>
      <v-text-field
        v-model="formData.password"
        outlined
        label="Mot de passe"
        :type="passwordFieldType"
        persistent-placeholder
        dense
        :placeholder="$t('placeholders.password')"
        :rules="[validations.required]"
      >
      </v-text-field>

      <div class="link-block">
        <router-link :to="{ name: 'ForgotPassword' }">
          {{ $t("authPages.loginPage.forgotPassword") }}
        </router-link>
      </div>

      <v-btn
        color="primary"
        depressed
        type="submit"
        class="form__submit"
        :loading="loading"
        :disabled="loading"
      >
        {{ $t("userActions.confirm") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { UserService } from "@/services/user.service.js";
import validations from "@/validations/index";
export default {
  name: "LoginForm",
  components: {
    LogoAuth: () => import("~cp/Logo/LogoAuth"),
  },
  data() {
    return {
      loading: false,
      valid: false,
      formData: {
        username: null,
        password: null,
      },
      iconActive: false,
      passwordFieldType: "password",
    };
  },
  computed: {
    validations() {
      return validations;
    },
  },
  methods: {
    logInGetToken() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;
      UserService.logInGetToken(this.formData)
        .then(({ data }) => {
          this.$store.commit("authModule/SET_ACCESS_TOKEN", data?.access_token);
          return UserService.getMe()
            .then(({ data }) => {
              this.$store.commit("authModule/SET_USER_PROFILE", data);
              this.$Tawk.$createChat({
                email: data.email,
                name: data.full_name,
              });
              this.$router.push({ name: "Home" });
            })
            .catch(({response}) => {
              this.$store.commit(
                "snackbarModule/SET_ERROR_API",
                response
              );
            });
        })
        .catch(({response}) => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t('snackbar.error.incorrectEmailOrPassword')
          );
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style src="../Form.scss" lang="scss" scoped></style>
